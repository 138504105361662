const React = require('react');
const PropTypes = require('prop-types');

const Image = require('../../components/image');
const Header = require('../../components/CollectionBox/header');

const FakeItem = ({ emptyStateCard, anchoredText }) => {
  const { title, icon } = emptyStateCard || {};

  return (
    <div className="collection-box-item collection-box-item--show collection-box__fake-item">
      {
        emptyStateCard && (
          <div className="collection-box-item__container">
            <div className="collection-box-item__image-container">
              <Image
                className="collection-box-item__image"
                src={icon}
                onload="on"
                alt={title}
              />
            </div>
            <div className="collection-box-item__header">
              <Header
                title={title}
              />
            </div>
            <div className="collection-box-item__tag">
              {anchoredText}
            </div>
          </div>
        )
      }
    </div>
  );
};

FakeItem.propTypes = {
  emptyStateCard: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }),
  anchoredText: PropTypes.string,
};

FakeItem.defaultProps = {
  emptyStateCard: null,
  anchoredText: '',
};

module.exports = FakeItem;
