const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const types = require('../Editable/types');

const EditableButtons = require('../EditableButtons');
const Editable = require('../Editable').default;

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const Wrapper = (props, context) => {
  const headerRef = React.createRef();
  const {
    i18n,
    componentId,
    flash,
    logo,
    logo_alignment: logoAlignment,
    logo_picture_version: logoVersion,
    logo_object_storage_url: logoStorageUrl,
    header_message: headerMessage,
    flash_action_message: flashActionMessage,
    flash_action_href: flashActionRef,
    flash_message: flashMessage,
    opacity,
    sale,
    categories,
    ths_enabled: thsEnabled,
    ths_checked: thsChecked,
    ths_message: thsMessage,
    ths_purchase_value: thsPurchaseValue,
    ths_url: thsUrl,
    favicon_object_storage_url: faviconUrl,
  } = props;

  const editableProps = {
    label: i18n.gettext('Editar'),
    type: types.HEADER,
    payload: {
      componentId,
      flash,
      logo,
      logoAlignment,
      logoVersion,
      logoStorageUrl,
      headerMessage,
      flashActionMessage,
      flashActionRef,
      flashMessage,
      thsEnabled,
      thsChecked,
      thsMessage,
      thsUrl,
      thsPurchaseValue,
      transparency: 1 - (Math.round(opacity * 100) / 100),
      sale,
      categories,
      faviconUrl,
    },
  };

  const { layoutTheme } = context.metadata;
  const editableClasses = classNames(
    'editable-wrapper',
    'header--editable',
    `header--editable--${layoutTheme}`,
    { 'header--editable--with-flash': props.flash },
  );

  return (
    <div className={editableClasses} ref={headerRef}>
      <Editable
        className="editable_fullheight"
        type={editableProps.type}
        payload={editableProps.payload}
        wrapperRef={headerRef}
      >
        <EditableButtons editButton={editableProps} showDelete={false} />
      </Editable>
    </div>
  );
};

Wrapper.propTypes = {
  i18n: PropTypes.shape(),
  componentId: PropTypes.string.isRequired,
  logo: PropTypes.shape(),
  flash: PropTypes.bool.isRequired,
  logo_picture_version: PropTypes.string.isRequired,
  logo_object_storage_url: PropTypes.string,
  logo_alignment: PropTypes.string,
  header_message: PropTypes.string,
  flash_action_message: PropTypes.string,
  flash_action_href: PropTypes.string,
  flash_message: PropTypes.string,
  opacity: PropTypes.number,
  ths_enabled: PropTypes.bool,
  ths_checked: PropTypes.bool,
  ths_message: PropTypes.string,
  ths_url: PropTypes.string,
  ths_purchase_value: PropTypes.number,
  sale: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(PropTypes.shape()),
};

Wrapper.defaultProps = {
  i18n: { gettext: f => f },
  logo: {},
  logo_alignment: 'left',
  logo_object_storage_url: null,
  header_message: null,
  flash_action_message: null,
  flash_action_href: null,
  flash_message: null,
  opacity: 1,
  sale: {},
  categories: null,
  ths_enabled: false,
  ths_checked: false,
  ths_message: '',
  ths_url: '',
  ths_purchase_value: undefined,
};

Wrapper.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = Wrapper;
