const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const { pathOr } = require('ramda');
const uuid = require('uuid/v5');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const { getComponent } = require('./componentMapper');
const {
  LABEL_IMAGE,
  LABEL_IMAGE_EDITABLE,
} = require('./componentNames');
const { useRenderContext } = require('../../pages/home/context');

const DisabledWrapper = require('../../components/disabled-wrapper');
const { isComponentOutsideLayout, getAdviceMessage } = require('./utils');
const { TYPES } = require('../../utils/constants/components/message');

const RenderTree = (props, { metadata: { deviceType } }) => {
  const { i18n, node } = props;

  const Component = getComponent(
    node.component,
    deviceType,
    pathOr(null, ['properties', 'display'], node),
  );

  const properties = node.properties || {};
  const children = node.children || [];

  // Editable components needs to send his id to admin in order to allow customization
  properties.componentId = node.id;
  // deviceType is added as it is needed in a component and it doesn't break anything to add it
  properties.deviceType = deviceType;
  // @FIXME we create a dummy RestClient for now, since we don't need it
  properties.restClient = { _dummy: true };
  // We add this properties for splinter components
  properties.name = node.component;
  properties.nodeItems = children;
  properties.nodeStyles = node.properties && node.properties.styles;
  // We need hidden prop for NOC components
  properties.hidden = node.hidden;
  if (node.component === LABEL_IMAGE || node.component === LABEL_IMAGE_EDITABLE) {
    properties.display = node.display;
  }

  const ComponentRender = () => (
    <Component {...properties} i18n={i18n}>
      {children.map((child, index) => (
        <RenderTree
          key={node.key || uuid(node.component + index, uuid.DNS)}
          node={child}
          i18n={i18n}
        />
      ))}
    </Component>
  );

  const DisabledRender = () => {
    const { hasAdviceMessage, adviceMessageType } = getAdviceMessage(children, properties);
    const hasAdviceWarningMessage = hasAdviceMessage && adviceMessageType === TYPES.warning;
    return (
      <>
        {
          (!hasAdviceWarningMessage && properties.hidden)
            ? (
              <DisabledWrapper componentId={properties.componentId} i18n={i18n}>
                <ComponentRender />
              </DisabledWrapper>
            )
            : <ComponentRender />
        }
      </>
    );
  };

  const componentInside = !isComponentOutsideLayout(node.component);
  const wrapperClasses = classNames(
    { 'component-wrapper': componentInside },
    { 'profile-header-wrapper': node.component === 'ProfileHeader' },
  );

  return (
    <>
      {componentInside ? (
        <div className={wrapperClasses}>
          <DisabledRender />
        </div>
      ) : <DisabledRender />
      }
    </>
  );
};

const RenderTreeContainer = (props) => {
  const { appearance } = useRenderContext();

  return <RenderTree {...props} node={appearance.getTree()} />;
};

// Recursive treeShape
const node = {
  component: PropTypes.string,
  properties: PropTypes.object,
};

RenderTree.contextTypes = {
  metadata: metadataPropType.isRequired,
};

RenderTree.propTypes = {
  i18n: PropTypes.shape({ gettext: PropTypes.func }),
  node: PropTypes.shape({ children: PropTypes.arrayOf(PropTypes.shape(node)), ...node }).isRequired,
};

RenderTree.defaultProps = {
  i18n: { gettext: f => f },
  uniqueId: 0,
};

module.exports = RenderTreeContainer;
