const CLASS_BY_VARIATION = {
  original: 'slide',
  material: 'slide-material',
};

const ASPECT_RATIOS_BY_SIZE = {
  mobile: {
    default: {
      small: (414 / 460).toFixed(2),
      medium: (414 / 530).toFixed(2),
      large: (414 / 575).toFixed(2),
    },
    explorer: {
      small: (414 / 360).toFixed(2),
      medium: (414 / 414).toFixed(2),
      large: (414 / 460).toFixed(2),
    },
    legend: {
      small: (414 / 460).toFixed(2),
      medium: (414 / 530).toFixed(2),
      large: (414 / 600).toFixed(2),
    },
    material: {
      small: (374 / 325).toFixed(2),
      medium: (374 / 374).toFixed(2),
      large: (374 / 420).toFixed(2),
    },
  },
  desktop: {
    default: {
      small: (1920 / 560).toFixed(2),
      medium: (1920 / 650).toFixed(2),
      large: (1920 / 780).toFixed(2),
    },
    explorer: {
      small: (1920 / 600).toFixed(2),
      medium: (1920 / 700).toFixed(2),
      large: (1920 / 800).toFixed(2),
    },
    legend: {
      small: (1920 / 750).toFixed(2),
      medium: (1920 / 840).toFixed(2),
      large: (1920 / 920).toFixed(2),
    },
    material: {
      small: (1200 / 400).toFixed(2),
      medium: (1200 / 480).toFixed(2),
      large: (1200 / 540).toFixed(2),
    },
  },
};

const BUTTON_COLORS = {
  explorer: '#000',
};

module.exports = { CLASS_BY_VARIATION, ASPECT_RATIOS_BY_SIZE, BUTTON_COLORS };
