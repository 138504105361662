const React = require('react');

const PropTypes = require('prop-types');
const classNames = require('classnames');

const Image = require('nordic/image');
const Section = require('../section');
const PlayIconVideo = require('./play-icon');
const metadataPropType = require('../metadataProvider/metadataPropType');
const { isYoutubeVideoUrl } = require('./utils');

const { useState } = React;

const VideoAndText = ({
  alignment,
  title,
  text,
  variant,
  video_link: videoLink,
}, { metadata: { layoutTheme } }) => {
  const [showVideo, setShowVideo] = useState(videoLink?.image === '');
  const sectionClassName = classNames('video-and-text', {
    'video-text--words-left': alignment === 'left',
    'video-text--words-center': alignment === 'center',
    'video-text--words-right': alignment === 'right',
    'video-text--video-variant1': variant === 'v1',
    'video-text--video-variant2': variant === 'v2',
    'video-text--video-variant3': variant === 'v3',
  });

  const sectionVideo = classNames('video-and-text__col', {
    'video-and-text__container-text': variant === 'v1',
    'video-and-text__container-text-variant2': variant === 'v2' && !showVideo,
    'video-and-text__container-text-variant3': variant === 'v3',
    'video-and-text__container-text-remove-text': variant === 'v2' && showVideo,
  });

  const showYoutube = classNames({
    'video-and-text__youtube-player': variant === 'v1' || variant === 'v2',
    'video-and-text__youtube-player-variant3': variant === 'v3',
  });

  const showPlayer = classNames({
    'video-and-text__image-player': variant === 'v1' || variant === 'v2',
    'video-and-text__image-player-variant3': variant === 'v3',
  });

  const onImageClick = () => {
    if (!videoLink.link) return;
    setShowVideo(true);
  };

  const getVideoLink = () => (videoLink.image !== '' && isYoutubeVideoUrl(videoLink.link) ? `${videoLink.link}?autoplay=1` : videoLink.link);

  return (
    <Section className={sectionClassName}>
      <div className="row container">
        <div className="video-and-text">
          <div className="video-and-text video-and-text__container">
            <div className={sectionVideo}>
              <h2 className="video-and-text__container-title">{!title.hidden && title.text}</h2>
              {!text.hidden
                && (
                  <div
                    className="video-and-text__container-description"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: text.text }}
                  />
                )
              }
            </div>
            <div className="video-and-text__col video-and-text__container-video">
              { showVideo
                ? (
                  <div className="video-and-text__image--center">
                    <iframe
                      title="Video frame"
                      className={showYoutube}
                      width="100%"
                      src={getVideoLink()}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      sandBox
                    />
                  </div>
                )
                : (
                  <div className="video-and-text__image--center">
                    <Image className={showPlayer} src={videoLink.image} alt={title.text} />
                    <PlayIconVideo onImageClick={onImageClick} theme={layoutTheme} />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

VideoAndText.propTypes = PropTypes.shape({
  alignment: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  video_link: PropTypes.shape({
    image: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
  }),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      link: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ),
}).isRequired;

VideoAndText.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = VideoAndText;
