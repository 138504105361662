const EXPLORER_THEME = 'explorer';
const LEGEND_THEME = 'legend';
const CAROUSEL_FULL = 'full';
const CAROUSEL_CONTENT = 'content';
const CAROUSEL_COLUMNS_1 = 1;
const CAROUSEL_COLUMNS_3 = 3;
const CAROUSEL_COLUMNS_4 = 4;
const MIN_CAROUSEL_ITEMS = 2;
const CAROUSEL_SPACING_24 = 24;
const CAROUSEL_SPACING_6 = 6;
const CAROUSEL_PAGINATION_POSITION = 'bottom';
const CAROUSEL_ARROW_SIZE = 'large';
const CAROUSEL_SHOW_ARROW = false;
const DEVICE_TYPE = 'mobile';
const COLUMN_MOBILE = 1.3;
const COLUMN_DESKTOP = 2;
const SCROLL_HIDDEN = 'hidden';
const SCROLL_VISIBLE = 'visible';

module.exports = {
  EXPLORER_THEME,
  LEGEND_THEME,
  CAROUSEL_FULL,
  CAROUSEL_CONTENT,
  CAROUSEL_COLUMNS_1,
  CAROUSEL_COLUMNS_3,
  CAROUSEL_COLUMNS_4,
  CAROUSEL_SPACING_6,
  CAROUSEL_SPACING_24,
  CAROUSEL_PAGINATION_POSITION,
  CAROUSEL_ARROW_SIZE,
  CAROUSEL_SHOW_ARROW,
  MIN_CAROUSEL_ITEMS,
  DEVICE_TYPE,
  COLUMN_MOBILE,
  COLUMN_DESKTOP,
  SCROLL_HIDDEN,
  SCROLL_VISIBLE,
};
