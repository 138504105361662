const React = require('react');
const PropTypes = require('prop-types');
const uuid = require('uuid/v4');

const { getCollectionBoxesProps } = require('./utils');
const FakeItem = require('./fakeItem');
const CollectionBoxes = require('./index');
const createEditable = require('../../adapters/createEditable');
const withEditable = require('../../adapters/withEditable');
const withHover = require('../../adapters/withHover');
const withOrder = require('../../adapters/withOrder');

const Wrapper = (props) => {
  const { children, empty_state_card: emptyStateCard, i18n } = props;

  const fakeItemCount = children?.length < 2 ? (2 - children.length) : 0;
  const fakeChildren = fakeItemCount > 0 ? Array.from(Array(fakeItemCount).keys()) : [];

  const propsFakeItem = {
    emptyStateCard,
    anchoredText: i18n.gettext('Anclada'),
  };

  const drawFakeItems = () => fakeChildren.map(() => <FakeItem key={uuid()} {...propsFakeItem} />);

  return <CollectionBoxes {...props} editable fakeChildren={drawFakeItems} />;
};

Wrapper.propTypes = {
  i18n: PropTypes.shape(),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  empty_state_card: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }),
};

Wrapper.defaultProps = {
  i18n: { gettext: f => f },
  empty_state_card: null,
};

module.exports = withHover(createEditable(withEditable(withOrder(Wrapper)), getCollectionBoxesProps));
