const { regularExpressions } = require('./constants');

const isYoutubeVideoUrl = url => {
  const regex = new RegExp(regularExpressions.IS_VIDEO_YOUTUBE);
  return regex.test(url);
};

module.exports = {
  isYoutubeVideoUrl,
};
