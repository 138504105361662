const React = require('react');
const PropTypes = require('prop-types');

const SlideCTA = ({ className, cta, buttonTextColor }) => {
  const textStyles = {
    color: `${buttonTextColor}`,
  };

  return (
    <button type="button" className={className}>
      <span style={textStyles}>{cta}</span>
    </button>
  );
};

SlideCTA.propTypes = {
  className: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string,
};

SlideCTA.defaultProps = {
  buttonTextColor: '',
};

module.exports = SlideCTA;
