const React = require('react');
const { string } = require('prop-types');
const { Image } = require('nordic/image');

const EshopBanner = (props) => {
  const { image } = props;

  const namespace = 'ui-ms-banner';

  return (
    <div className={`${namespace}`} data-testid="eshop-banner">
      <Image className={`${namespace}__image`} src={image} alt="banner" />
    </div>
  );
};

EshopBanner.propTypes = {
  image: string.isRequired,
};

module.exports = EshopBanner;
