/**
 * Module dependencies
 */
const React = require('react');
const Price = require('loki-price');
const PropTypes = require('prop-types');

/**
 * PriceBlockOriginal Component
 */
const PriceBlockOriginal = ({ price, discount, installments }) => (
  <div className="ui-item__price-block">
    {price.text && <span className="ui-item__price">{price.text}</span>}
    {/**
        * Discount price: disabled if an original_price exists
        */}
    {(discount && (discount.discount_type !== 'VOLUME'))
      && <Price amount={discount.original_price} className="ui-item__discount-price" disabled />}
    {/**
        * The main price of the item
        */}
    {price.currency_id && <Price amount={price} className="ui-item__price" />}
    {/**
        * Discount percentage tag with the diff between original_price and price. For example: "25% OFF"
        */}
    {discount && <span className="ui-item__discount-text">{discount.text}</span>}
    {/**
        * Installments as text. For example: "6x $5.000 sin interés"
        */}
    {installments && <span className="ui-item__installments">{installments}</span>}
  </div>
);

PriceBlockOriginal.propTypes = {
  discount: PropTypes.shape({
    discount_type: PropTypes.string,
    original_price: PropTypes.shape({
      cents: PropTypes.string,
      currency_id: PropTypes.string,
      fraction: PropTypes.string,
      symbol: PropTypes.string,
    }),
    text: PropTypes.string,
  }),
  display: PropTypes.string,
  price: PropTypes.shape({
    cents: PropTypes.string,
    currency_id: PropTypes.string,
    currency_symbol: PropTypes.string,
    decimal_separator: PropTypes.string,
    decimals: PropTypes.string,
    fraction: PropTypes.string,
    original_price: PropTypes.string,
    price: PropTypes.string,
    symbol: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.number,
    whole: PropTypes.string,
  }).isRequired,
  installments: PropTypes.string,
};

PriceBlockOriginal.defaultProps = {
  discount: null,
  display: 'original',
  installments: null,
};

/**
 * Expose PriceBlockOriginal
 */
module.exports = PriceBlockOriginal;
