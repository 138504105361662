const React = require('react');
const PropTypes = require('prop-types');

const Card = require('@andes/card');
const Style = require('nordic/style');

const Action = require('../../components/action');
const Condition = require('./components/Condition');
const Title = require('../../components/landing-title');
const NotFoundScreen = require('../NotFoundScreen');

const namespace = 'ui-ms-bank-conditions';

/* eslint-disable react/no-danger */
const BankConditionsInfo = ({ i18n, components, styles }) => {
  const { title, promos, bank_discounts: bankDiscounts, special_promo: specialPromo, view_promotions: viewPromotions } = components;
  const { large } = styles;
  const specialPromoCondition = specialPromo !== null && specialPromo.length !== 0 ? specialPromo : [];

  const conditions = promos.concat(bankDiscounts, specialPromoCondition);

  if (conditions.length === 0) {
    return (
      <section className="bank-conditions-landing__section" data-testid="bank-conditions-landing">
        <NotFoundScreen
          title={i18n.gettext('Parece que esta página no existe')}
          description=""
          link={{
            text: i18n.gettext('Volver átras'),
            href: '/',
          }}
        />
      </section>
    );
  }

  return (
    <section className="bank-conditions-landing__section" data-testid="bank-conditions-landing">
      <Style renderChildrenInPlace={false}>{Object.values(large).join(' ')}</Style>

      <Title title={title} namespace={namespace} />
      <Card className="bank-conditions-landing__card">
        {conditions.map(condition => (
          <Condition
            key={`${condition.id}-${condition.name}`}
            id={!condition.id && condition.payment_id ? condition.payment_id : condition.id}
            name={condition.name}
            installmentsLabel={condition.installments_label}
            methodNames={condition.method_names}
            title={`${condition.name} ${condition.financial_label ? `| ${condition.financial_label}` : ''}`}
            legals={condition.legals}
          />
        ))}
        {viewPromotions && (
          <Action
            target={`${viewPromotions.target}${
              (!viewPromotions.html_target || viewPromotions.html_target !== '_blank')
                ? '?modal=true&flow=true'
                : ''
            }`}
            label={viewPromotions.label}
            modal_title={viewPromotions.modal_title}
          />
        )}
      </Card>
    </section>
  );
};

BankConditionsInfo.propTypes = {
  components: PropTypes.objectOf({
    site_id: PropTypes.string,
    title: PropTypes.string.isRequired,
    promos: PropTypes.arrayOf(PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      installments_label: PropTypes.string.isRequired,
      legals: PropTypes.string.isRequired,
      financial_label: PropTypes.string,
      method_names: PropTypes.string.isRequired,
      max_installments: PropTypes.number,
    })).isRequired,
    bank_discounts: PropTypes.arrayOf(PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      installments_label: PropTypes.string.isRequired,
      legals: PropTypes.string.isRequired,
      financial_label: PropTypes.string,
      method_names: PropTypes.string.isRequired,
    })),
    special_promo: PropTypes.arrayOf(PropTypes.objectOf({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      installments_label: PropTypes.string.isRequired,
      legals: PropTypes.string.isRequired,
      financial_label: PropTypes.string,
      method_names: PropTypes.string.isRequired,
      max_installments: PropTypes.number,
    })),
  }).isRequired,
  styles: PropTypes.objectOf({
    large: PropTypes.objectOf({
      sprite_css: PropTypes.string.isRequired,
      picture_css: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

BankConditionsInfo.defaultProps = {
  components: {
    title: '',
    promos: [],
    bank_discounts: [],
    special_promo: [],
    view_promotions: [],
  },
  styles: {
    large: {},
  },
};

module.exports = BankConditionsInfo;
