/**
 * Module dependencies
 */
const React = require('react');
const Price = require('loki-price');
const PropTypes = require('prop-types');

/**
 * PriceBlockMaterial Component
 */
const PriceBlockMaterial = ({ price, discount, installments }) => (
  <div className="ui-item__material-price-block">
    {price.text
      ? <span className="ui-item__price">{price.text}</span> : null}
    {/**
    * The main price of the item
    */}
    {price.currency_id && <Price amount={price} className="ui-item__material-price" />}
    {/*
    * Material theme definition for discount price
    */}
    {(discount && (discount.discount_type !== 'VOLUME'))
      && <Price amount={discount.original_price} className="ui-item__material-discount-price" disabled />}
    {/**
    * Installments as text. For example: "6x $5.000 sin interés"
    */}
    {installments && <span className="ui-item__material-installments">{installments}</span>}
  </div>
);

PriceBlockMaterial.propTypes = {
  discount: PropTypes.shape({
    discount_type: PropTypes.string,
    original_price: PropTypes.shape({
      cents: PropTypes.string,
      currency_id: PropTypes.string,
      fraction: PropTypes.string,
      symbol: PropTypes.string,
    }),
    text: PropTypes.string,
  }),
  display: PropTypes.string,
  price: PropTypes.shape({
    cents: PropTypes.string,
    currency_id: PropTypes.string,
    currency_symbol: PropTypes.string,
    decimal_separator: PropTypes.string,
    decimals: PropTypes.string,
    fraction: PropTypes.string,
    original_price: PropTypes.string,
    price: PropTypes.string,
    symbol: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.number,
    whole: PropTypes.string,
  }).isRequired,
  installments: PropTypes.string,
};

PriceBlockMaterial.defaultProps = {
  discount: null,
  display: 'original',
  installments: null,
};

/**
 * Expose PriceBlockMaterial
 */
module.exports = PriceBlockMaterial;
