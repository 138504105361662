const ProfileHeader = require('@mshops-components-library/profile-header').default;
const DebugInfo = require('../DebugInfo');
const UnknownComponent = require('./UnknownComponent');

/**
 * NORMAL LIST OF COMPONENTS
 */
const Document = require('../Document');
const Layout = require('../Layout');
const Row = require('../Row');

const ErrorScreen = require('../ErrorScreen');
const NotFoundScreen = require('../NotFoundScreen');

const HelpComponent = require('../HelpComponent');

const EshopBanner = require('../EshopBanner');
const BadgeCarousel = require('../BadgeCarousel');
const CarouselDesktop = require('../Carousel/desktop');
const CarouselMobile = require('../Carousel/mobile');
const CarouselWithTabsDesktop = require('../TabbedCarousel/desktop');
const CarouselWithTabsMobile = require('../TabbedCarousel/mobile');
const Discovery = require('../Discovery');
const LabelImage = require('../../components/labelImage');
const Mosaic = require('../Mosaic');
const NewsLetterModal = require('../NewsLetterModal');
const PaymentMethodsDesktop = require('../PaymentMethods/desktop');
const PaymentMethodsMobile = require('../PaymentMethods/mobile');
const ShoppingInfoDesktop = require('../ShoppingInfo/desktop');
const ShoppingInfoMobile = require('../ShoppingInfo/mobile');
const ContentList = require('../ContentList');
const Slider = require('../Slider');

const ContactForm = require('../ContactForm');
const ContactInfo = require('../ContactInfo');

const CollectionGridDesktop = require('../CollectionGrid/desktop');
const CollectionGridMobile = require('../CollectionGrid/mobile');

const PriceAndImage = require('../PriceAndImage');

const HowBuyInfo = require('../HowBuyInfo');

const CancelBuyInfo = require('../CancelBuyInfo');

const BankConditionsInfo = require('../BankConditionsInfo');

const DataPrivacyInfo = require('../DataPrivacyInfo');
const LegalNoticesInfo = require('../LegalNoticesInfo');
const AdhesionContractInfo = require('../AdhesionContractInfo');
const BuyerInfo = require('../BuyerInfo');
const SectionText = require('../SectionText');
const CategoryGallery = require('../CategoryGallery');
const CategoryLabelImage = require('../../components/CategoryLabelImage');
const Video = require('../VideoAndText');
const CategoryCarousel = require('../CategoryCarousel');
const CollectionBoxes = require('../CollectionBoxes');
const CollectionBox = require('../../components/CollectionBox');

/**
 * EDITABLE LIST OF COMPONENTS
 * */
const DocumentEditable = require('../Document/editable');
const LayoutEditable = require('../Layout/editable');

const RowEditable = require('../Row/editable');

const HeaderEditable = require('../Header/editable');
const EshopBannerEditable = require('../EshopBanner/editable');
const BannerEditable = require('../Banner/editable');
const BadgeCarouselEditable = require('../BadgeCarousel/editable');
const ShoppingInfoDesktopEditable = require('../ShoppingInfo/editable.desktop');
const ShoppingInfoMobileEditable = require('../ShoppingInfo/editable.mobile');
const ContentListEditable = require('../ContentList/editable');
const SliderEditable = require('../Slider/editable');
const WidgetsEditable = require('../WidgetsEditable/editable');

const CarouselDesktopEditable = require('../Carousel/desktop.editable');
const CarouselMobileEditable = require('../Carousel/mobile.editable');
const DiscoveryEditable = require('../Discovery/editable');
const MosaicEditable = require('../Mosaic/editable');

const CarouselWithTabsDesktopEditable = require('../TabbedCarousel/desktop.editable');
const CarouselWithTabsMobileEditable = require('../TabbedCarousel/mobile.editable');

const PaymentMethodsDesktopEditable = require('../PaymentMethods/desktop.editable');
const PaymentMethodsMobileEditable = require('../PaymentMethods/mobile.editable');

const ContactFormEditable = require('../ContactForm/editable');
const ContactInfoEditable = require('../ContactInfo/editable');

const CollectionGridDesktopEditable = require('../CollectionGrid/desktop.editable');
const CollectionGridMobileEditable = require('../CollectionGrid/mobile.editable');

const FooterEditable = require('../Footer/editable');
const PriceAndImageEditable = require('../PriceAndImage/editable');

const HowBuyInfoEditable = require('../HowBuyInfo/editable');
const BuyerInfoEditable = require('../BuyerInfo/editable');
const SectionTextEditable = require('../SectionText/editable');
const VideoEditable = require('../VideoAndText/editable');
const CategoryGalleryEditable = require('../CategoryGallery/editable');
const CategoryCarouselEditable = require('../CategoryCarousel/editable');
const CollectionBoxesEditable = require('../CollectionBoxes/editable');

/**
 * SPLINTER COMPONENTS
 */
const splinterComponents = require('../SplinterComponents/splinterComponents');

/**
 * EXPORTS
 */
module.exports = {
  defaultComponent: UnknownComponent,
  /**
   * All the available components
   */
  nonEditables: {
    Document,
    Layout,
    Row,
    ErrorScreen,
    NotFoundScreen,
    HelpComponent,
    ContactForm,
    ContactInfo,
    EshopBanner,
    ProfileHeader,
    Slider,
    LabelImage,
    Carousel: BadgeCarousel,
    Discovery,
    Mosaic,
    NewsLetterModal,
    HowBuyInfo,
    CancelBuyInfo,
    BankConditionsInfo,
    DataPrivacyInfo,
    LegalNoticesInfo,
    AdhesionContractInfo,
    CategoryCarouselV2: CategoryCarousel,
    PaymentMethods: {
      mobile: PaymentMethodsMobile,
      desktop: PaymentMethodsDesktop,
    },
    ShoppingInfo: {
      mobile: ShoppingInfoMobile,
      desktop: ShoppingInfoDesktop,
    },
    ContentList,
    BuyerInfo,
    Collection: {
      displayAs: {
        carousel: { mobile: CarouselMobile, desktop: CarouselDesktop },
        original: { mobile: CarouselMobile, desktop: CarouselDesktop },
        material: { mobile: CarouselMobile, desktop: CarouselDesktop },
        default: { mobile: CarouselMobile, desktop: CarouselDesktop },
      },
    },
    TabbedCarousel: {
      mobile: CarouselWithTabsMobile,
      desktop: CarouselWithTabsDesktop,
    },
    CollectionGrid: {
      displayAs: {
        original: {
          mobile: CollectionGridMobile,
          desktop: CollectionGridDesktop,
        },
        material: {
          mobile: CollectionGridMobile,
          desktop: CollectionGridDesktop,
        },
        default: {
          mobile: CollectionGridMobile,
          desktop: CollectionGridDesktop,
        },
      },
    },
    PriceAndImage,
    CategoryGallery,
    CategoryLabelImage,
    SectionText,
    Video,
    DebugInfo,
    CollectionBoxes,
    CollectionBox,
    ...splinterComponents.nonEditables,
  },
  /**
   * Components with an editable version
   */
  editables: {
    SectionTextEditable,
    DocumentEditable,
    LayoutEditable,
    RowEditable,
    EshopBannerEditable,
    ProfileHeader,
    HeaderEditable,
    BannerEditable,
    SliderEditable,
    CategoryCarouselV2Editable: CategoryCarouselEditable,
    CarouselEditable: BadgeCarouselEditable,
    CollectionEditable: {
      displayAs: {
        carousel: {
          mobile: CarouselMobileEditable,
          desktop: CarouselDesktopEditable,
        },
        original: {
          mobile: CarouselMobileEditable,
          desktop: CarouselDesktopEditable,
        },
        material: {
          mobile: CarouselMobileEditable,
          desktop: CarouselDesktopEditable,
        },
        default: {
          mobile: CarouselMobileEditable,
          desktop: CarouselDesktopEditable,
        },
      },
    },
    TabbedCarouselEditable: {
      mobile: CarouselWithTabsMobileEditable,
      desktop: CarouselWithTabsDesktopEditable,
    },
    CollectionGridEditable: {
      displayAs: {
        original: {
          mobile: CollectionGridMobileEditable,
          desktop: CollectionGridDesktopEditable,
        },
        material: {
          mobile: CollectionGridMobileEditable,
          desktop: CollectionGridDesktopEditable,
        },
        default: {
          mobile: CollectionGridMobileEditable,
          desktop: CollectionGridDesktopEditable,
        },
      },
    },
    DiscoveryEditable,
    MosaicEditable,
    HowBuyInfoEditable,
    BuyerInfoEditable,
    PaymentMethodsEditable: {
      mobile: PaymentMethodsMobileEditable,
      desktop: PaymentMethodsDesktopEditable,
    },
    ShoppingInfoEditable: {
      mobile: ShoppingInfoMobileEditable,
      desktop: ShoppingInfoDesktopEditable,
    },
    ContentListEditable,
    ContactFormEditable,
    ContactInfoEditable,
    WidgetsEditable,
    FooterEditable,
    PriceAndImageEditable,
    VideoEditable,
    CategoryGalleryEditable,
    CollectionBoxesEditable,
    ...splinterComponents.editables,
  },
};
