const splinterComponents = require('./splinterComponents');

const splinterConverter = ({
  name,
  variations,
  nodeItems,
  nodeStyles,
  ...props
}) => {
  // Splinter uses the same name as us for some of his components, so in order to avoid conflicts
  // we have to use a different name and refactor it to its original form, before rendering the component
  // eslint-disable-next-line security/detect-object-injection
  const componentName = splinterComponents[name] || name;

  const splinterNode = {
    component_name: componentName,
    variations,
    styles: nodeStyles || {}, // some splinter components requires styles to be defined
    data: { ...props },
    items: [],
  };

  if (componentName === 'Card' && splinterNode.data.image_src) {
    splinterNode.data.image_src = splinterNode.data.image_src.replace('.webp', '.jpg');
  }

  if (nodeItems && nodeItems.length) {
    splinterNode.items = nodeItems.map(({ component, properties, children }) => splinterConverter({
      name: component,
      variations: properties.variations,
      nodeItems: children,
      nodeStyles: properties.styles,
      ...properties,
    }));
  }

  return splinterNode;
};

module.exports = { splinterConverter };
