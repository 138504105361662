/* eslint-env browser */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const SlideCTA = require('./SlideCTA');

const metadataPropType = require('../metadataProvider/metadataPropType');
const { CLASS_BY_VARIATION, BUTTON_COLORS, ASPECT_RATIOS_BY_SIZE } = require('./config');
const classesHelper = require('../../utils/customBEMHelper');

const SlideImage = (
  { variation, image, text_alignment: textAlignment, heading, title,
    subtitle, cta, title_text_color: titleTextColor, fullWidth, sliderSizeName },
  { metadata: { deviceType, layoutTheme } },
) => {
  const classes = classesHelper({ name: CLASS_BY_VARIATION[variation] });
  const buttonTextColor = BUTTON_COLORS[layoutTheme] || titleTextColor;
  const { url_alternative: urlAlternative, url, alignment } = image;
  const imageMobile = urlAlternative || url;
  const showText = (heading.length > 0 || title.length > 0 || subtitle.length > 0 || cta.length > 0);

  const imageAdjustedClass = classNames(
    classes('image'),
    fullWidth ? 'image-adjust-width' : 'image-adjust-height',
  );

  const textWrapperClasses = classes('text-wrapper', {
    decorated: Boolean(heading.trim() || title.trim() || subtitle.trim()),
  });

  const getImageAspectRatio = () => {
    if (fullWidth) {
      return 'auto';
    }
    const ratioSettings = ASPECT_RATIOS_BY_SIZE[deviceType];
    const ratioSizes = ratioSettings[layoutTheme] || ratioSettings.default;
    const ratio = ratioSizes[sliderSizeName];
    return `${ratio}`;
  };

  const imageStyles = {
    backgroundImage: `url("${(deviceType === 'mobile') ? imageMobile : url}")`,
    backgroundPosition: alignment,
    aspectRatio: getImageAspectRatio(),
  };

  const textStyles = {
    color: `${titleTextColor}`,
  };

  return (
    <div className={imageAdjustedClass} style={imageStyles}>
      {showText && (
        <div className={classes('text', textAlignment)}>
          <div className={textWrapperClasses}>
            <h4 style={textStyles} className={classes('heading')}>{heading}</h4>
            <h2 style={textStyles} className={classes('title')}>{title}</h2>
            <h3 style={textStyles} className={classes('subtitle')}>{subtitle}</h3>
          </div>
          {cta && (
            <SlideCTA
              className={classes('cta')}
              cta={cta}
              buttonTextColor={buttonTextColor}
            />
          )}
        </div>
      )}
    </div>
  );
};

SlideImage.propTypes = {
  image: PropTypes.shape({
    alignment: PropTypes.oneOf(['top', 'center', 'bottom']).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    url_alternative: PropTypes.string,
  }).isRequired,
  heading: PropTypes.string,
  title: PropTypes.string,
  title_text_color: PropTypes.string,
  subtitle: PropTypes.string,
  text_alignment: PropTypes.string.isRequired,
  cta: PropTypes.string,
  variation: PropTypes.oneOf(['original', 'material']).isRequired,
  fullWidth: PropTypes.bool,
  sliderSizeName: PropTypes.string,
};

SlideImage.defaultProps = {
  heading: '',
  title: '',
  title_text_color: '',
  subtitle: '',
  cta: '',
  fullWidth: false,
  sliderSizeName: '',
};

SlideImage.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = SlideImage;
